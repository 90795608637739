@font-face {
    font-family: 'roboto light';
    src: url(./../fonts/roboto/Roboto-Light.ttf);
}
@font-face {
    font-family: 'roboto medium';
    src: url(./../fonts/roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: 'spacemono regular';
    src: url(./../fonts/spacemono/SpaceMono-Regular.ttf);
}
@font-face {
    font-family: 'poppins bold';
    src: url(./../fonts/poppins/Poppins-Bold.ttf);
}
@font-face {
    font-family: 'poppins medium';
    src: url(./../fonts/poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: 'poppins regular';
    src: url(./../fonts/poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: 'poppins light';
    src: url(./../fonts/poppins/Poppins-Light.ttf);
}
@font-face {
    font-family: 'acosta';
    src: url(./../fonts/acosta/Acosta.otf);
}
@font-face {
    font-family: 'montserrat';
    src: url(./../fonts/montserrat-v25-latin-regular.ttf);
}

/* -------------------
custom_-_variables
------------------ */
:root {
    /* -------- defaults -------- */
    --d-white: #FFFFFF;
    --d-body: #FCFDFE;
    --d-black: #161718;

    /* -------- neutral -------- */
    --n50: #FFFFFF;
    --n75: #FFFFFF;
    --n100: #FFFFFF;
    --n200: #FFFFFF;
    --n300: #F9F9F9;
    --n400: #B3B3B3;
    --n500: #9C9C9C;

    /* -------- primary -------- */
    --p50: #FFECE6;
    --p75: #FFB396;
    --p100: #FF936B;
    --p200: #FF652B;
    --p300: #FF4500;
    --p400: #B33000;
    --p500: #9C2A00;

    /* -------- secondary -------- */
    /* --s50: #E6F8FF;
    --s75: #96E3FF;
    --s100: #6BD7FF;
    --s200: #2BC6FF;
    --s300: #00BAFF;
    --s400: #0082B3;
    --s500: #00719C; */
    --s50: #E8E8EA;
    --s75: #A1A1AB;
    --s100: #7A7A88;
    --s200: #414154;
    --s300: #1A1A31;
    --s400: #121222;
    --s500: #10101E;

    /* work_-_cc */
    --light-blue: #1D2235;
    --lighter-blue: #252B43;
    --bright-blue: #252B43;
    --dark-blue: #16192A;
    --flash-blue: #5C26F6;

    /* layer_-_cc */
    --layer-main-dark: #000000;
    --layer-light-dark: #1C1C1C;
    --layer-white: #F2F2F2;
    --layer-color-dark: #878787;
    --layer-lighter-dark: rgba(242, 242, 242, 0.12);

    /* logo_-_cc */
    --logo-light-red: #ED051F;
    --logo-dark-red: #75000E;
    --logo-light-blue: #120B34;
    --logo-dark-blue: #0D0D1F;

    /* rich_-_cc */
    --rich-green-main: #428432;
    --rich-green-grad-1: #A2F39B;
    --rich-green-grad-2: #0B9F05;
    --rich-green-grad-3: #3C7F2C;
    --rich-yellow-main: #EECF02;
    --rich-red: #E31B1E;

    /* peki-inu_-_cc */
    --peki-b1: #02041A;
    --peki-b2: #403E56;
    --peki-y1: #FDE66F;
    --peki-y2: #FCBF30;
    --peki-y2: #FCBF30;
    --peki-y3: #FFCD6B;
    --peki-y4: #FED001;
    --peki-y5: #D98801;
    --peki-y6: #F7C244;
    --peki-p1: #FA0282;
    --peki-g1: #9D9D4C;
    --peki-g2: #BCBC6B;

    /* pop_-_cc */
    --pop-g1: #E36E11;
    --pop-g2: #FDA529;
    --pop-g3: #FEDB4B;
    --pop-b1: #007ABF;
    --pop-b1: #022365;
    --pop-gr1: #17AE2D;

    /* dog_-_cc */
    --dog-y1: #FEF830;
    --dog-y2: #FF9201;
    --dog-y3: #FFDF01;
    --dog-y4: #BC4028;
    --dog-y5: #E27017;

    --dog-p1: #F10271;
    --dog-p2: #FB1F41;
    --dog-p3: #D70190;
    --dog-p4: #F567A6;

    --dog-b1: #0B417D;
    --dog-b2: #03C6F2;
    --dog-b3: #0163D0;
    --dog-b4: #04003D;

    /* flexible */

    /*  */
    --error-bg: #ffefef;
    --error-act: #e7195a;
    --sus-act: #008000;
}


/* -------------------
global_-_styles
------------------- */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.home {
    overflow-y: hidden;
}
.picker-result-box {
    overflow-y: hidden;
}

*:not(.the-feedbacks) {
    overflow-x: hidden;
}

.news-column-2 .height-50 {
    height: 193px;
}

*:not(i) {
    font-family: 'roboto light';
    /* font-weight: 400; */
}

::before,
::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    background: var(--d-black);
    /* background: var(--layer-main-dark); */
    /* background: var(--logo-dark-blue); */
}

.flex-100 {
    flex: 0 0 100%;
    max-width: 100%;
}

.btn-og {
    display: inline-block;
    background: var(--rich-green-main);
    /* background: var(--logo-dark-red); */
    padding: 0 27px;
    height: 45px;
    border-radius: 30px;
    cursor: pointer;
    transition: .5s;
}
.btn-og p {
    font-size: 18px;
    font-family: 'poppins medium';
    /* color: var(--layer-main-dark); */
    color: var(--layer-white);
}

.btn-og.dark {
    /* background: var(--layer-light-dark); */
    background: var(--logo-light-blue);
}
.btn-og.dark p {
    color: var(--d-white);
}

.btn-1 {
    display: inline-block;
    padding: 12px 25px;
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    border-radius: 30px;
    font-size: 18px;
    font-weight: 700;
    color: var(--layer-light-dark);
    font-family: "poppins medium";
    cursor: pointer;
    transition: .5s;
}

.btn-sp {
    display: inline-block;
    padding: 12px 20px;
    background: var(--peki-p1);
    border-radius: 30px;
    font-size: 17px;
    font-weight: 600;
    color: var(--n75);
    cursor: pointer;
    transition: .5s;
}

.btn-2 {
    display: inline-block;
    padding: 15px 35px;
    background: transparent;
    border-radius: 35px;
    font-family: 'open sans';
    font-size: 18;
    font-weight: 600;
    color: var(--t300);
    transition: .5s;
}
.btn-2:hover {
    background: var(--n300);
}

.main-frame {
    max-width: 1300px;
    margin: 0 auto;
}

@media (max-width: 1300px) {
    .main-frame {
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }
}

.wider-frame-percentage {
    max-width: 96%;
    margin: 0 auto;
}

.mid-frame {
    width: 1000px;
    margin: 0 auto;
}
@media (max-width: 1000px) {
    .mid-frame {
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.position-relative {
    position: relative;
}

.center-width {
    margin: 0 auto;
}

.transition-05 {
    transition: .5s;
}

.display-inline-block {
    display: inline-block;
}
.display-inline {
    display: inline;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.red_b {
    background: red;
}
.purple_b {
    background: purple;
}
.wheat_b {
    background: wheat;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .25);
    z-index: 111;
}

.toast-container {
    position: fixed;
    top: -70px;
    z-index: 999;
    width: 100%;
    transition: .2s;
}
.toast-container.active {
    top: 0;
    
}

.toast-box {
    padding: 20px 20px;
    background: rebeccapurple;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}
.toast-box.error {
    background: crimson;
}
.toast-box.nice {
    background: #4BB543;
}

.toast-box p {
    color: var(--n75);
}

/* ------------------------------------
animation_-_s
-------------------------------------- */

@keyframes back {
    100% {
        background-position: 2000px 0;
    }
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes loadText {
    0% {
        width: 0;
    }
    70% {
        width: 100%;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes topBounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(25px);
    }
}

@keyframes leftBounce {
    0%,
    100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(25px);
    }
}

@keyframes animateWave {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}

@keyframes animateWave_02 {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes moving {
    0% {
        left: -20px;
    }
    100% {
        left: 100%;
    }
}

@keyframes bounce {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(15px);
    }
}

@keyframes scaleAnt {
    0%,
    100% {
        scale: .9;
    }
    50% {
        scale: 1;
    }
}

@keyframes shakeScale {
    0% {
        transform: rotateX(0deg);
    }
    10% {
        transform: rotateX(-20deg);
    }
    20% {
        transform: rotateX(20deg);
    }
    30% {
        transform: rotateX(-30deg);
    }
    40% {
        transform: rotateX(30deg);
    }
    50% {
        transform: translateX(0);
    }
    60% {
        scale: .7;
    }
    10% {
        scale: 1;
    }
}



/* header_-_styles */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 90px;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: .5s;
}
header.scroll {
    height: 90px;
    padding: 0 70px;
    background: var(--d-black);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

header .logo {
    width: 80px;
}

header .logo img {
    width: 150px;
}

header ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 60px;
}

header ul li a {
    font-size: 17px;
    font-weight: 500;
    font-family: 'poppins medium';
    color: var(--d-body);
    position: relative;
    display: inline-block;
    text-decoration: none;
    /* padding-bottom: 0.75rem; */
    transition: 0.7s;
}
header ul li a:hover {
    color: var(--dog-y2);
}
header ul .nav-link.active a {
    color: var(--dog-y2);
}

header ul li a:not(.gs-btn) {
    line-height: 40px;
}

header ul .nav-link::after {
    content: '';
    width: 0;
    height: 2px;
    background: linear-gradient(to right, var(--dog-y3), var(--dog-y2));
    display: block;
    margin: auto;
    transition: 0.5s;
}

header ul .nav-link.active::after {
    width: 100%;
}

header ul .nav-link:hover::after {
    width: 100%;
}

header ul .join-btn::after {
    content: '';
    width: 100%;
    height: 2px;
    background: var(--peki-y2);
    display: block;
    margin: auto;
}

header .gs-btn {
    display: inline-block;
    padding: 0.75rem 1.6rem;
    border-radius: 40px;
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    font-weight: 700;
    font-size: 18px;
    color: var(--layer-light-dark);
    transition: .5s;
}


.nav-icon {
    cursor: pointer;
}
.nav-icon img {
    width: 30px;
    object-fit: contain;
}


.mobile-nav {
    background: var(--dog-y2);
    padding: 50px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    position: fixed;
    top: 80px;
    right: 0;
    transform: translateX(4000px);
    z-index: 888;
    transition: transform .5s ease-in-out;
}
.mobile-nav.active {
    transform: translateX(0);
}

.mobile-nav-item a {
    font-size: 20px;
    font-weight: 700;
    color: var(--d-black);
}


/* home_-_styles */
.home-cont {
    width: 1200px;
    margin: 0 auto;
}

.home {
    padding: 120px 0 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)), url(./../media/img/dog_banner.jpg);
    /* background: var(--d-black); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
}

/* .home-text {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 50px;
} */

.home-text {
    overflow-y: hidden;
    flex: 0 0 50%;
    max-width: 50%;
}

.home-head-subhead {
    margin-bottom: 30px;
}

.home-text h2 {
    text-transform: capitalize;
    font-size: 60px;
    color: var(--layer-white);
    font-family: 'poppins bold';
    line-height: 60px;
    overflow-y: hidden;
    font-weight: 700;
    margin-bottom: 20px;
}

.home-text h2 span {
    font-weight: 700;
    color: var(--dog-y2);
    font-family: 'poppins bold';
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-text h3 {
    font-size: 16px;
    color: var(--layer-white);
    font-family: 'spacemono regular';
    font-weight: 300;
}

.home-ct-icon-box {
    width: 50px;
    height: 50px;
    background: var(--peki-y2);
    border-radius: 50%;
    cursor: pointer;
}

.home-ct-icon-box img {
    width: 30px;
    /* height: 25px; */
    /* object-fit: contain; */
}

.copy-address-box {
    margin-bottom: 30px;
    border-radius: 30px;
    width: 470px;
    height: 45px;
    border: 1px solid var(--layer-white);
}

.copy-address-box img {
    width: 20px;
    filter: invert(1);
    cursor: pointer;
}

.copy-address-box p {
    color: var(--layer-white);
    font-size: 17px;
    font-weight: 500;
    font-family: 'poppins medium';
}

.home-img {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: end;
}

.home-img-by-text {
    font-size: 50px;
    font-family: 'acosta';
    color: var(--layer-white);
    text-align: center;
    -webkit-text-stroke: 2px var(--dog-y3);
    /* text-stroke: 1px black;  */
}

.home-img-line-box div {
    height: 4px;
    width: 50px;
    background: var(--dog-y3);
}

.home-img-line-box p {
    font-size: 50px;
    font-family: 'poppins bold';
    color: var(--dog-y1);
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    -webkit-text-stroke: 2px var(--layer-white);
    /* text-stroke: 1px black;  */
}

.home-text-logo-ant {
    width: 450px;
    animation: spin 5s ease-in-out infinite alternate;
}

.home-ca-cont {
    display: inline-block;
    background: var(--dog-b3);
    padding: 9px 15px;
    background: cornsilk;
    border: 4px solid var(--dog-y2);
    box-shadow: var(--dog-y2) 4px 4px 0px -0px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.home-ca-cont h3 {
    color: var(--layer-light-dark);
    font-size: 20px;
    font-family: 'poppins regular';
    /* margin-bottom: 3px; */
}

.home-ca-box {
    padding: 5px 7px;
    border-radius: 5px;
    background: var(--layer-light-dark);
}

.home-ca-box p {
    color: var(--layer-white);
    font-size: 15px;
    font-family: 'spacemono regular';
}


/* about_-_styles */
.about {
    padding: 100px 0;
    position: relative;
    background: var(--d-black);
}

.about .main-frame {
    max-width: 1400px;
}

.about-text {
    flex: 0 0 50%;
    max-width: 50%;
}

.about-text h2 {
    font-size: 19px;
    font-family: 'poppins regular';
    margin-bottom: 20px;
    color: var(--dog-y3);
}

.about-text h3 {
    font-size: 30px;
    font-family: 'poppins medium';
    margin-bottom: 40px;
    color: var(--dog-y3);
    line-height: 38px;
    overflow-y: hidden;
}

.about-text p {
    font-size: 18px;
    font-family: 'poppins medium';
    margin-bottom: 40px;
    color: var(--layer-white);
}

.about-btn {
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    width: 300px;
    height: 55px;
    border-radius: 30px;
    cursor: pointer;
}

.about-btn span {
    font-size: 19px;
    font-weight: 700;
    color: var(--layer-light-dark);
    font-family: 'montserrat';
}

.about-btn img {
    width: 35px;
}

.about-img {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: end;
}

.about-img img {
    width: 500px;
    border-radius: 50%;
    animation: scaleAnt 11s ease-in-out infinite alternate;
}
.about-img video {
    height: 400px;
}

.htb {
    padding: 50px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, .7)), url(./../media//img/logo-3d.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.htb-header {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.htb-header h2 {
    font-family: 'montserrat';
    font-size: 17px;
    font-weight: 700;
    color: cornsilk;
    margin-bottom: 10px;
}

.htb-header h3 {
    font-family: 'poppins bold';
    font-size: 60px;
    line-height: 50px;
    overflow-y: hidden;
    color: cornsilk;
}

.htb-item {
    /* background: var(--pop-g1); */
    background: linear-gradient(to right, var(--pop-g1), var(--pop-g2));
    padding: 30px 40px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 4px solid var(--peki-b1);
}

.htb-item-inner img {
    width: 100px;
    margin-bottom: 10px;
}

.htb-item-inner h4 {
    font-family: 'roboto medium';
    font-size: 22px;
    color: var(--layer-main-dark);
    text-align: center;
    margin-bottom: 20px;
}

.htb-item-inner p {
    font-family: 'montserrat';
    font-weight: 600;
    font-size: 16px;
    color: var(--layer-light-dark);
}


/* tokenomics_styles */
.tokenomics {
    padding: 70px 0;
}

.tk-header {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.tk-header p {
    font-family: 'montserrat';
    font-size: 16px;
    font-weight: 700;
    color: cornsilk;
    margin-bottom: 10px;
}

.tk-header h2 {
    font-family: 'poppins bold';
    font-size: 40px;
    line-height: 50px;
    overflow-y: hidden;
    color: cornsilk;
}

.tk-header h2 span {
    font-family: 'poppins bold';
    color: var(--pop-g1);
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.token-table table {
    border: .5px solid var(--text-black-300);
    border-collapse: collapse;
    margin-bottom: 30px;
}

.token-table table th {
    font-size: 17px;
    font-family: 'acosta';
    color: var(--d-black);
    padding: 10px 0;
    /* background: var(--pop-g1); */
    background: linear-gradient(to bottom, var(--dog-y3), var(--dog-y2));
    border: .5px solid var(--pop-g1);
}

.token-table table td {
    border: .5px solid cornsilk;
    width: 300px;
    padding: 10px 10px;
}

.token-table table td {
    font-size: 17px;
    font-family: 'spacemono regular';
    color: cornsilk;
}

.tax-heading {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.tax-heading h2 {
    font-family: 'acosta';
    font-size: 20px;
    font-weight: 500;
    color: cornsilk;
}

.tax-box {
    overflow-y: hidden;
}

.tokenomics .token-item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
}

.token-item-inner {
    width: 100%;
    text-align: center;
}

.token-item-inner p {
    font-size: 20px;
    line-height: 50px;
    color: var(--main-color-1);
}

.tk-icon-container {
    background: var(--main-color-3);
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow-y: hidden;
}

.token-item-inner h2 {
    font-size: 17px;
    font-family: 'Montserrat';
    color: var(--text-black-200);
    margin-bottom: 20px;
}

.token-item-inner h3 {
    font-size: 17px;
    font-weight: 100;
    color: var(--text-black-200);
}

.token-item-inner h3 span {
    color: var(--main-color);
}


/* roadmap_-_styles */

.roadmap {
    padding: 70px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, .6)), url(./../media/img/dl_roadmap_bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background: linear-gradient(to bottom, var(--d-black) 0%, var(--d-black) 50%, #F9F9F9 50%, #F9F9F9 100%); */
}

.roadmap-header {
    text-align: center;
    width: 100%;
    margin-bottom: 60px;
}

.roadmap-header h2 {
font-family: 'montserrat';
    font-size: 17px;
    font-weight: 700;
    color: cornsilk;
    margin-bottom: 10px;
}

.roadmap-header h3 {
    font-family: 'poppins bold';
    font-size: 60px;
    line-height: 50px;
    overflow-y: hidden;
    color: cornsilk;
}

.rd-item {
    border: 3px solid var(--dog-y2);
    border-radius: 10px;
    background: cornsilk;
    padding: 30px;
}
/* .rd-item::before {
    content: "";
    background: var(--pop-g1);
    width: 100%;
    height: 100%;
    position: absolute;
    right: -5px;
} */

.rd-item h2 {
    font-family: 'acosta';
    font-size: 20px;
    color: var(--d-black);
    /* background: linear-gradient(to right, var(--pop-g1), var(--pop-g2), var(--pop-g3));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin-bottom: 30px;
}

.rd-list-box:not(:last-child) {
    margin-bottom: 7px;
}

.rd-list-box div {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background: var(--dog-y2);
    border: 1px solid var(--d-black);
}

.rd-list-box div img {
    width: 10px;
}

.rd-list-box p {
    font-family: 'roboto medium';
    color: var(--d-black);
    font-size: 16px;
}




/* eco_-_system */
.eco {
    padding: 50px 0;
}

.eco-header {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
}

.eco-header h2 {
    font-family: 'poppins bold';
    font-size: 60px;
    line-height: 50px;
    overflow-y: hidden;
    color: cornsilk;
}

.eco-container {
    background: var(--peki-y2);
    border-radius: 30px;
    padding: 50px 30px;
}

.eco-text {
    flex: 0 0 60%;
    max-width: 0 0 60%;
}

.eco-text h2 {
    font-family: 'poppins bold';
    font-size: 60px;
    line-height: 50px;
    overflow-y: hidden;
    color: var(--layer-light-dark);
    margin-bottom: 10px;
}

.eco-pgh {
    font-family: 'roboto medium';
    font-size: 19px;
    color: var(--layer-light-dark);
    margin-bottom: 30px;
}

.eco-text .copy-address-box {
    margin-bottom: 30px;
    border-color: var(--layer-light-dark);
}

.eco-text .copy-address-box img {
    filter: invert(0);
}

.eco-text .copy-address-box p {
    color: var(--layer-light-dark);
}

.eco-text .btn-1 {
    background: var(--peki-g2);
    color: var(--layer-light-dark);
    font-size: 19px;
    font-weight: 700;
    font-family: 'montserrat';
    padding: 17px 40px;
}

.eco-text .btn-sp {
    background: var(--peki-g2);
    color: var(--layer-light-dark);
    font-size: 19px;
    font-weight: 700;
    font-family: 'montserrat';
    padding: 17px 40px;
}

.eco-line {
    background: var(--layer-light-dark);
    width: 90%;
    height: 1.5px;
    margin-top: 13px;
    margin-bottom: 13px;
}

.eco-list {
    font-family: 'roboto medium';
    font-size: 19px;
    color: var(--layer-light-dark);
    margin-bottom: 30px;
}

.eco-list span {
    font-family: 'roboto medium';
    font-size: 19px;
    color: var(--layer-light-dark);
    margin-bottom: 30px;
}


.eco-img {
    flex: 0 0 40%;
    max-width: 0 0 40%;
    display: flex;
    justify-content: center;
}

.eco-img img {
    animation: shakeScale 3s ease-in-out infinite alternate;
    border-radius: 50%;
}


/* partners_-_style */
.partners {
    padding: 50px 0;
    background: var(--layer-white);
}

.partners-header {
    margin-bottom: 30px;
    width: 100%;
}

.partners-header h2 {
    font-family: 'verdana';
    font-size: 40px;
    color: var(--layer-main-dark);
    margin-bottom: 10px;
    text-align: center;
}

.partners-header p {
    font-family: 'inter';
    font-size: 18px;
    color: var(--peki-y2);
    text-align: center;
}

.partners-item {
    flex: 0 0 33%;
    max-width: 33%;
}

.partners-item-img {
    width: 70%;
}


/* join_-_styles */
.join {
    padding: 70px 0;
}

.join-bg-1 {
    background: linear-gradient(to right, var(--pop-g1), var(--pop-g2), var(--pop-g3));
    padding: 3px;
    border-radius: 20px;
}

.join-bg-2 {
    background: linear-gradient(30deg, #161718 35%, #1b1d1e 35%, #1b1d1e 62%, #161718 62%);
    border-radius: 17px;
    padding: 40px 10px;
}

.join-bg-2 .row {
    justify-content: center;
}

.j-icons {
    margin-bottom: 20px;
}

.j-icons img {
    width: 40px;
    filter: invert(1);

}

.join-text {
    text-align: center;
}

.join-text h3 {
    font-size: 25px;
    text-transform: capitalize;
    font-family: 'acosta';
    color: var(--layer-white);
    margin-bottom: 10px;
}

.join-text p {
    font-size: 20px;
    text-transform: capitalize;
    font-family: 'montserrat';
    color: var(--layer-white);
    margin-bottom: 30px;
}







/* footer_-_styles */
.footer {
    border-top: .5px solid var(--layer-lighter-dark);
    position: relative;
    padding: 20px;
    /* border-top-left-radius: 125px; */
    /* background: var(--main-background); */
    padding: 100px 30px 30px;
}

.footer-item-1 {
    flex: 0 0 40%;
    max-width: 40%;
    padding: 0 50px;
}

.footer-item-1 .logo {
    width: 50%;
    margin-bottom: 5px;
    animation: spin 5s ease-in-out infinite alternate;
}

.footer-item-1 h2 {
    color: var(--main-color-2);
    background: linear-gradient(to top, var(--main-color-2), var(--text-black-sp));
    font-size: 40px;
    font-family: 'GoodTimes';
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 10px;
}

.footer-item-1 p {
    color: var(--d-white);
    font-size: 20px;
    font-family: 'inter';
    margin-bottom: 20px;
}

.footer-item-1 h3 {
    width: fit-content;
    margin-bottom: 40px;
    position: relative;
    font-weight: 500;
    font-family: 'verdana';
    color: var(--d-white);
    font-size: 20px;
    line-height: 30px;
}

.footer-item-1 form {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--text-black-200);
    margin-bottom: 50px;
}

.footer-item-1 form .far {
    font-size: 18px;
    margin-right: 10px;
    color: var(--text-pure-white);
}

.footer-item-1 form input {
    width: 100%;
    background: transparent;
    color: var(--text-black-200);
    border: 0;
    outline: none;
}

.footer-item-1 form button {
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.footer-item-1 form button .fas {
    font-size: 16px;
    color: var(--bg-black-50);
}

.footer-item-1 .social-icons a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    display: inline-block;
}

.footer-item-1 .social-icons a div {
    height: 100%;
}
.footer-item-1 .social-icons a img {
    width: 25px;
}

.footer-item-2 {
    flex: 0 0 20%;
    max-width: 20%;
}

.footer-item-2 h3 {
    width: fit-content;
    margin-bottom: 40px;
    position: relative;
    font-family: 'verdana';
    font-weight: 500;
    color: var(--d-white);
    font-size: 20px;
    line-height: 30px;
}

.footer-item-2 ul li {
    list-style: none;
    margin-bottom: 10px;
}

.footer-item-2 ul li a {
    text-decoration: none;
    color: #fff;
    line-height: 20px;
    font-family: 'inter';
    text-transform: capitalize;
}

.underline {
    width: 100%;
    height: 5px;
    background: var(--pop-g1);
    border-radius: 3px;
    position: absolute;
    top: 25px;
    left: 0;
    overflow: hidden;
}

.underline span {
    width: 15px;
    height: 100%;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 10px;
    animation: moving 2s linear infinite;
}

hr {
    width: 90%;
    border: 0;
    border-bottom: 1px solid var(--d-white);
    margin: 20px auto;
}

.copyright {
    text-align: center;
    color: var(--d-white);
    font-family: 'verdana';
}

.footer .main-container {
    padding: 0 20px;
}

.footer-row-box {
    padding: 32px;
    border-radius: 10px;
    /* background: linear-gradient(30deg, var(--dog-y2) 35%, var(--dog-y3) 35%, var(--dog-y3) 62%, var(--dog-y2) 62%); */
    background: cornsilk;
    border: 4px solid var(--dog-y2);
    box-shadow: var(--dog-y2) 4px 4px 0px -0px;
}

.ft-row-icon-box {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background: var(--dog-y2);
    border: 2px solid var(--layer-light-dark);
}

.ft-row-icon-box img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.footer-row-box .btn-1 {
    border-radius: 10px !important;
    border: 2px solid var(--layer-light-dark) !important;
    font-size: 25px !important;
}